// <reference path="../../Decorators.ts" />
/// <reference path="../../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../../Scripts/TypeScript/angularjs/angular-animate.d.ts"/>
/// <reference path="../../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../../Scope.d.ts"/>
/// <reference path="../../Window.d.ts" />
/// <reference path="../../Scripts/TypeScript/gsap/index.d.ts" />

declare var ease, TimelineMax, TweenMax, Power4, Power1, Power2, Power3, Bounce, Elastic: any;
import Guid = System.Guid;

interface SignalR {
    agentHub: Umbrella.Modules.IAgentHub;
}

interface Number {
    format(n, x, s, c): string;
    toHHMMSS(): string;
}

angular
    .module('Umbrella')
    .config([
        '$compileProvider',
        '$httpProvider',
        '$locationProvider',
        '$qProvider',
        ($compileProvider, $httpProvider, $locationProvider: ng.ILocationProvider, $qProvider) => {
            $locationProvider.hashPrefix('');
            $compileProvider.debugInfoEnabled(window.config && window.config.configuration === 'DEBUG');
            $httpProvider.useApplyAsync(true);
            $qProvider.errorOnUnhandledRejections(false);

            $compileProvider.aHrefSanitizationWhitelist(
                /^\s*(https?|ftp|mailto|file|tel|pronto|navision|tobias|mycorsa|ax|dynamicsnav|chromeielink):/
            );
        }
    ])
    .filter('unsafe', ['$sce', $sce => val => $sce.trustAsHtml(val)])
    .filter('partialstring', function() {
        return function(input, numWords) {
            let inputParts = input.split(' ');
            let output = '';
            for (let i = 0; i < inputParts.length; i++) {
                if (i == numWords) {
                    break;
                }
                output = output + inputParts[i] + ' ';
            }
            return output;
        };
    })
    .filter('timeago', function() {
        return function(input, pAllowFuture) {
            if (input === null) return 'voor altijd';

            let nowTime = new Date().getTime();
            let date = new Date(input).getTime();

            //var refreshMillis= 6e4; //A minute
            let allowFuture = pAllowFuture || false,
                strings = {
                    prefixAgo: null,
                    prefixFromNow: 'over',
                    suffixAgo: 'geleden',
                    suffixFromNow: null,
                    seconds: '1 minuut',
                    minute: '1 minuut',
                    minutes: '%d minuten',
                    hour: '1 uur',
                    hours: '%d uur',
                    day: '1 dag',
                    days: '%d dagen',
                    month: '1 maand',
                    months: '%d maanden',
                    year: '1 jaar',
                    years: '%d jaar',
                    wordSeparator: undefined
                },
                dateDifference = nowTime - date,
                seconds = Math.abs(dateDifference) / 1000,
                minutes = seconds / 60,
                hours = minutes / 60,
                days = hours / 24,
                years = days / 365,
                separator = strings.wordSeparator === undefined ? ' ' : strings.wordSeparator,
                // var strings = this.settings.strings;
                prefix = strings.prefixAgo,
                suffix = strings.suffixAgo;

            let substitute = function(stringOrFunction, number, strs) {
                let string = $.isFunction(stringOrFunction)
                    ? stringOrFunction(number, dateDifference)
                    : stringOrFunction;
                let value = (strs.numbers && strs.numbers[number]) || number;
                return string.replace(/%d/i, value);
            };

            if (allowFuture) {
                if (dateDifference < 0) {
                    prefix = strings.prefixFromNow;
                    suffix = strings.suffixFromNow;
                }
            }

            let words =
                (seconds < 45 && substitute(strings.seconds, Math.round(seconds), strings)) ||
                (seconds < 90 && substitute(strings.minute, 1, strings)) ||
                (minutes < 45 && substitute(strings.minutes, Math.round(minutes), strings)) ||
                (minutes < 90 && substitute(strings.hour, 1, strings)) ||
                (hours < 24 && substitute(strings.hours, Math.round(hours), strings)) ||
                (hours < 42 && substitute(strings.day, 1, strings)) ||
                (days < 30 && substitute(strings.days, Math.round(days), strings)) ||
                (days < 45 && substitute(strings.month, 1, strings)) ||
                (days < 365 && substitute(strings.months, Math.round(days / 30), strings)) ||
                (years < 1.5 && substitute(strings.year, 1, strings)) ||
                substitute(strings.years, Math.round(years), strings);

            return $.trim([prefix, words, suffix].join(separator));
        };
    })
    .filter('timeagobasic', [
        'timeagoFilter',
        'timedisplayFilter',
        (timeagoFilter, timedisplayFilter) => (input, pAllowFuture) => {
            let now = new Date();
            let then = new Date(input);
            let timeDiff = Math.abs(then.getTime() - now.getTime());
            let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

            //if (diffDays > 3) {
            return ` op ${timedisplayFilter(input)} uur`;
            //}

            //return timeagoFilter(input, pAllowFuture);
        }
    ])
    .filter('truncate', function() {
        return function(text, length, end) {
            if (text != undefined) {
                if (isNaN(length)) {
                    length = 10;
                }

                if (!end) {
                    end = '...';
                }

                if (text.length <= length || text.length - end.length <= length) {
                    return text;
                } else {
                    return String(text).substring(0, length - end.length) + end;
                }
            }

            return text;
        };
    })
    .filter('businessdaysago', function() {
        return function(input) {
            let date = new Date(input);
            if (date.isValid()) {
                let today = new Date();
                return today.businessDaysDifference(date);
            }

            return Number.MAX_VALUE;
        };
    })
    .filter('hoursago', function() {
        return function(input) {
            let date = new Date(input);

            if (date.isValid()) {
                let today = new Date();
                let hours = (<any>today - <any>date) / 3600000;
                return hours;
            }

            return Number.MAX_VALUE;
        };
    })
    .filter('datetime', ['$filter', $filter => input => $filter('date')(input, 'yyyy-MM-dd HH:mm:ss')])
    .filter('timedisplay', function() {
        return function(input) {
            if (!input) return '';
            let date = new Date(input); //  + "+0" + (new Date().getTimezoneOffset() / -60) + ":00"
            return (
                date.getDate() +
                ' ' +
                date.getMonthName('nl') +
                ' ' +
                date.getFullYear() +
                ' om ' +
                ('0' + date.getHours()).slice(-2) +
                ':' +
                ('0' + date.getMinutes()).slice(-2)
            );
        };
    })
    .filter('mailto', function(s) {
        return '<a href="mailto:' + s + '">' + s + '</a>';
    })
    .directive('ngBlur', [
        '$parse',
        '$rootScope',
        function($parse, $rootScope: Umbrella.IUmbrellaRootScope) {
            return function(scope, element, attr) {
                let fn = $parse(attr.ngBlur);
                element.bind('blur', function(event) {
                    $rootScope.safeApply(function() {
                        fn(scope, { $event: event });
                    });
                });
            };
        }
    ])
    .directive('umbrellaDraggable', [
        function() {
            return function(scope, element) {
                element.draggable({
                    handle: $('h2', element)
                });
            };
        }
    ])
    .directive('ngConfirmClick', [
        function() {
            return {
                priority: -1,
                restrict: 'A',
                link(scope, element, attrs) {
                    element.bind('click', function(e) {
                        let message = attrs.ngConfirmClick;
                        if (message && !confirm(message)) {
                            e.stopImmediatePropagation();
                            e.preventDefault();
                        }
                    });
                }
            };
        }
    ])
    .directive('umbrellaAuth', [
        function() {
            return {
                priority: -1,
                restrict: 'A',
                link(scope, element, attr) {
                    let perm = attr.umbrellaAuth;

                    if (perm && (!window.user || !window.user.permissions[perm])) {
                        toggleElementsDueToAuthorization(element, attr, true);
                    }
                }
            };
        }
    ])
    .directive('umbrellaScrollInfinite', [
        function() {
            return function(scope, $elem, attr) {
                const mainEl = <HTMLElement>document.getElementById('main');
                mainEl.addEventListener('ps-y-reach-end', () => {
                    scope.$apply(attr.umbrellaScrollInfinite);
                });
            };
        }
    ])
    .directive('selectOnClick', [
        '$window',
        function($window) {
            return {
                restrict: 'A',
                link(scope, element, attrs) {
                    element.on('click', function() {
                        if (!$window.getSelection().toString()) {
                            // Required for mobile Safari
                            this.setSelectionRange(0, this.value.length);
                        }
                    });
                }
            };
        }
    ])
    .directive('umbrellaPhone', [
        '$filter',
        'PhoneService',
        function($filter, PhoneService) {
            return {
                restrict: 'A',
                scope: {
                    value: '='
                },
                link($scope, $element) {
                    let isValidPhoneNr = function(nr) {
                        return nr && nr !== 'Niet bekend';
                    };

                    let nrClick = function() {
                        if (isValidPhoneNr($scope.value)) {
                            PhoneService.callNr($scope.value);
                        }
                    };

                    $element.bind('click', nrClick);
                    $scope.$on('$destroy', function() {
                        $element.unbind('click', nrClick);
                    });

                    $scope.$watch('value', function(newvalue) {
                        if (isValidPhoneNr(newvalue)) {
                            $element.html($filter('phonenr')(newvalue));
                            $element.addClass('clickable');
                        } else {
                            $element.html('Niet bekend');
                            $element.removeClass('clickable');
                        }
                    });
                }
            };
        }
    ])
    .directive('formatcurrency', function() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link(scope: any, element: any, attr: any, ngModel: any) {
                /**
                 * Number.prototype.format(n, x, s, c)
                 *
                 * @param integer n: length of decimal
                 * @param integer x: length of whole part
                 * @param mixed   s: sections delimiter
                 * @param mixed   c: decimal delimiter
                 */
                Number.prototype.format = function(n, x, s, c) {
                    let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
                        num = this.toFixed(Math.max(0, ~~n));

                    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
                };

                function format(text) {
                    let price = +text;
                    if (isNaN(price)) return text;
                    return price.format(2, 3, '.', ',');
                }

                function parse(number) {
                    /*
                     * Currency validation:
                     * (\d+)         At least one digit
                     * (.\d{3})*     Optional groups of three digits seperated by a dot
                     * (,\d{1,2})?   Optionally, end with a decimal separator (ie. comma) followed by one or two digits.
                     */

                    if (number) {
                        let regex = /^(\d+)(.\d{3})*(,\d{1,2})?$/;
                        if (!regex.test(number.toString())) {
                            ngModel.$setValidity(attr.ngModel, false);
                            return undefined;
                        }
                        ngModel.$setValidity(attr.ngModel, true);
                        return number
                            .toString()
                            .replace('.', '')
                            .replace(',', '.');
                    } else {
                        return number;
                    }
                }

                ngModel.$formatters.push(format);
                ngModel.$parsers.push(parse);
            }
        };
    })
    .filter('useFilter', [
        '$filter',
        $filter => {
            return function() {
                let filterName = [].splice.call(arguments, 1, 1)[0];

                //check if an additional parameter is provided to the filter that needs to be applied
                let filterParam = null;
                if (filterName.indexOf(':') > -1) {
                    filterParam = filterName.split(':')[1];
                    filterName = filterName.split(':')[0];
                }

                if (filterName.trim() === '') filterName = 'none';

                let applyParams = [arguments[0], filterParam];
                return $filter(filterName).apply(null, applyParams);
            };
        }
    ])
    .filter('none', function() {
        return function(input) {
            return input;
        };
    })
    .filter('card', function() {
        return function(input) {
            switch (input) {
                case 0:
                case 'CustomerCard':
                    return 'relatiekaart';
                case 2:
                case 'ColleagueCard':
                    return 'collegakaart';
                case 4:
                case 'UnitCard':
                    return 'objectkaart';
                case 5:
                case 'CustomerHistoryCard':
                    return 'klanthistorie';
                case 6:
                case 'ComplexCard':
                    return 'complexkaart';
                case 7:
                case 'Portal':
                    return 'klantportaal';
                default:
                    return '';
            }
        };
    })
    .filter('entityType', function() {
        return function(input) {
            switch (input) {
                case 'Unit':
                    return 'Eenheid';
                case 'Customer':
                    return 'Klant';
                case 'Contract':
                    return 'Contract';
                case 'Role':
                    return 'Rol';
                default:
                    return input;
            }
        };
    })
    .filter('time', function() {
        return function(input) {
            return secondsToHms(input);
        };
    })
    .filter('bytestosize', function() {
        let bytesToSize = function(bytes, precision) {
            /**
             * Convert number of bytes into human readable format
             *
             * @param integer bytes     Number of bytes to convert
             * @param integer precision Number of digits after the decimal separator
             * @return string
             */
            if (!bytes) return '';

            let kilobyte = 1024;
            let megabyte = kilobyte * 1024;
            let gigabyte = megabyte * 1024;
            let terabyte = gigabyte * 1024;

            if (bytes >= 0 && bytes < kilobyte) {
                return bytes + ' b';
            } else if (bytes >= kilobyte && bytes < megabyte) {
                precision = 0; //override
                return (bytes / kilobyte).toFixed(precision) + ' Kb';
            } else if (bytes >= megabyte && bytes < gigabyte) {
                return (bytes / megabyte).toFixed(precision) + ' Mb';
            } else if (bytes >= gigabyte && bytes < terabyte) {
                return (bytes / gigabyte).toFixed(precision) + ' Gb';
            } else if (bytes >= terabyte) {
                return (bytes / terabyte).toFixed(precision) + ' Tb';
            } else {
                return bytes + ' b';
            }
        };

        return function(input) {
            return bytesToSize(input, 0);
        };
    })
    .filter('filetype', function() {
        return function(input) {
            if (!input) return input;

            let extension = input
                .split('.')
                .pop()
                .toLowerCase();
            switch (extension) {
                case 'pdf':
                    return 'PDF-document';
                case 'doc':
                case 'docx':
                    return 'Word-document';
                case 'jpg':
                case 'gif':
                case 'png':
                    return 'afbeelding';
                default:
                    return extension + '-document';
            }
        };
    })
    .filter('emptyDash', function() {
        return function(input) {
            if (!input) return '-';

            return input;
        };
    })
    .filter('recentlyChanged', function() {
        /**
         * Filter to detect whether an item has 'recently' changed.
         * used for knowledge base items.
         * currently set to 3 days.
         */
        return function(input) {
            let date = new Date(input);
            let today = new Date();
            let days = (<any>today - <any>date) / 86400000;

            return days <= 3;
        };
    })
    .filter('age', function() {
        return function(input) {
            let today = new Date();
            let birthDate = new Date(input);
            let age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();

            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }

            if (isNaN(age)) return 'onbekend';

            return age + ' jaar';
        };
    })
    .filter('newlines', function() {
        return function(text) {
            if (!text) return text;

            return text.replace(/\n/g, '<br/>');
        };
    })
    .filter('formatMailAddress', function() {
        return function(emailObj) {
            if (!emailObj || !emailObj.emailAddress) return '';

            if (emailObj.displayName) return '"' + emailObj.displayName + '" <' + emailObj.emailAddress + '>';

            return emailObj.emailAddress;
        };
    })
    .filter('returnChatType', () => {
        return externalConversationId => {
            return externalConversationId ? 'WhatsApp' : 'LiveChat';
        };
    })
    .filter('contactType', () => {
        return conversation => {
            if (conversation.customer.id) return
            return conversation.externalConversationId ? conversation.customer.phoneNumber : conversation.customer.email;

        };
    })
    .factory('UmbrellaPage', [
        '$rootScope',
        function($rootScope) {
            let title = 'Umbrella';

            return {
                title() {
                    return title;
                },
                setTitle(newTitle) {
                    //title = newTitle;
                    $rootScope.title = newTitle;
                }
            };
        }
    ])
    .factory('AgentHub', function() {
        return $.connection.agentHub;
    })
    .value('ui.config', {
        date: {
            dateFormat: 'dd-mm-yy',
            dayNamesMin: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
            dayNames: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],
            firstDay: 1,
            monthNames: [
                'Januari',
                'Februari',
                'Maart',
                'April',
                'Mei',
                'Juni',
                'Juli',
                'Augustus',
                'September',
                'Oktober',
                'November',
                'December'
            ],
            nextText: 'Volgende',
            prevText: 'Vorige'
        },
        jq: {
            qtip: {
                position: {
                    my: 'left center',
                    at: 'right center'
                }
            }
        }
    })
    .constant('EmptyGuid', '00000000-0000-0000-0000-000000000000')
    /*.animation('.slide-down', function () {
        return {

            beforeAddClass: function (element, className, done) {
                if (className === 'ng-hide') {
                    element.slideUp('fase', done);
                } else {
                    done();
                }
            },

            removeClass: function (element, className, done) {
                if (className === 'ng-hide') {
                    element.hide();
                    element.slideDown(400, 'easeInOutCubic', done);
                }
                else {
                    done();
                }
            }
        };
    })*/
    .value('initFormElements', initFormElements)
    .value('initResponsiveElements', initResponsiveElements)
    .value('initAutoGrow', initAutoGrow)
    .animation('.swap-animation', swapAnimation)
    .animation('.slide-animation', swapAnimation);

function swapAnimation() {
    return {
        enter: function enter(element, done) {
            TweenMax.from(element, 0.5, {
                autoAlpha: 0,
                scale: 0,
                delay: 0.25,
                ease: Power4.easeInOut,
                onComplete: done
            });
        },
        leave: function leave(element, done) {
            TweenMax.to(element, 0.5, {
                autoAlpha: 0,
                scale: 0,
                ease: Power4.easeInOut,
                onComplete: done
            });
        }
    };
}

function initFormElements() {
    setTimeout(function() {
        initTextboxes();
        initAutoGrow();
        initRippleEffects();
    }, 500);
}

//	Initialize Textbox/Area Events (error state / counters):
function initTextboxes() {
    //  CSS Classes and Text Counters onLoad:
    $('.formitem .inputField').each(function() {
        let input = $(this).val();
        if ($(this).hasClass('limited')) {
            let limit = $(this).attr('data-limit');
            if (input.length >= 0) {
                $(this)
                    .parent()
                    .find('.webformCounter')
                    .html(input.length + ' / ' + limit);
                if (input.length > limit) {
                    $(this).addClass('error');
                    $(this)
                        .parent()
                        .find('.webformCounter')
                        .addClass('error');
                }
            }
        }
        //  Hotwire Textbox/Textarea onKeyUp:
        $(this).on('keyup', function() {
            let input = $(this).val().length;
            if ($(this).hasClass('limited')) {
                let limit = $(this).attr('data-limit');
                $(this)
                    .parent()
                    .find('.webformCounter')
                    .html($(this).val().length + ' / ' + limit);
                if (input > limit) {
                    $(this).addClass('error');
                    $(this)
                        .parent()
                        .find('.webformCounter')
                        .addClass('error');
                } else {
                    $(this).removeClass('error');
                    $(this)
                        .parent()
                        .find('.webformCounter')
                        .removeClass('error');
                }
            } else {
                $(this)
                    .removeClass('error')
                    .find('span')
                    .not('.bar')
                    .fadeOut(350)
                    .html();
                if ($(this).val() !== '') {
                    $(this).removeClass('error');
                    $(this)
                        .parent()
                        .find('span')
                        .not('.bar')
                        .fadeOut(350 * 2);
                }
            }
        });
    });
}

//	Initialize Ripple Effects onClick:
function initRippleEffects() {
    let $button_ink = $('.button').not('.disabled, .no-ink'),
        $ink,
        x,
        y,
        ink_diameter;
    $button_ink.on('click', function(e) {
        let $this = $(this);
        $this.removeClass('inked');
        // Avoid adding multiple ink elements
        if (!$this.find('.ink').length) {
            // no ink element added yet
            $this.prepend('<span class="ink"></span>');
            $ink = $this.find('.ink');
        } else {
            // Ink element added
            $ink = $this.find('.ink');
            $ink.removeClass('spill');
        }
        ink_diameter = Math.max($this.outerWidth(), $this.outerHeight());
        // x and y coordinates for ink element
        x = e.pageX - $this.offset().left - ink_diameter / 2;
        y = e.pageY - $this.offset().top - ink_diameter / 2;
        $ink.css({
            top: y,
            left: x,
            width: ink_diameter,
            height: ink_diameter
        }).addClass('spill');
        $this.addClass('inked');
    });
}

//	Initialize Textarea AutoGrow (size scaling with input length):
function initAutoGrow() {
    setTimeout(function() {
        $('textarea.inputField, textarea.autogrow').each(function() {
            let $this = $(this),
                minHeight = $this.height();

            let shadow = $('<div></div>')
                .css({
                    position: 'absolute',
                    top: -10000,
                    left: -10000,
                    width: $(this).outerWidth(),
                    fontSize: $this.css('fontSize'),
                    fontFamily: $this.css('fontFamily'),
                    lineHeight: $this.css('lineHeight'),
                    resize: 'none'
                })
                .appendTo(document.body);

            let update = function() {
                let times = function(string, number) {
                    let r = '';
                    for (let i = 0; i < number; i++) r += string;
                    return r;
                };
                let val = this.value
                    .replace(/</g, '&lt;')
                    .replace(/>/g, '&gt;')
                    .replace(/&/g, '&amp;')
                    .replace(/\n$/, '<br/>&nbsp;')
                    .replace(/\n/g, '<br/>')
                    .replace(/ {2,}/g, function(space) {
                        return times('&nbsp;', space.length - 1) + ' ';
                    });
                shadow.html(val);
                $(this).css('height', Math.max(shadow.height() + 5, minHeight));
            };
            $(this)
                .change(update)
                .keyup(update)
                .keydown(update);
            update.apply(this);
        });
    }, 0);
}

//  (JH) - Initialize Responsive Elements (timed out [meh...] to allow all html to be rendered):
function initResponsiveElements() {
    //  Initialize Sidebar NavIcons:
    setTimeout(function() {
        initSidebarMenus();
    }, 500);
}

//  Hotwire Sidebar NavIcons:
function initSidebarMenus() {
    //
    let content = $('#content');
    let sidebarTelephony = $('#sidebar_telephony');
    let sidebarSearch = $('.top-search');

    //  Init. NavIcon (Menu sidebar):
    $('#navicon')
        .off('click')
        .on('click', function() {
            $('.navigation-buttons .fa').removeClass('active');

            //  Close Search sidebar if open, and open Navigation sidebar:
            if (content.hasClass('openLeftSearch')) {
                content.removeClass('openLeftSearch').addClass('openLeft');
                $(this)
                    .find('.fa')
                    .addClass('active');
            }
            //  Close Navigation sidebar if open:
            else if (content.hasClass('openLeft')) {
                content.removeClass('openLeft');
            }
            //  Close Navigation sidebar if closed:
            else {
                content.addClass('openLeft');
                $(this)
                    .find('.fa')
                    .addClass('active');
            }
            //  Close Card sidebar if open:
            if (content.hasClass('openRight')) {
                content.removeClass('openRight');
            }
        });

    //  Init. SearchIcon (Search sidebar):
    $('#searchicon a')
        .off('click')
        .on('click', function() {
            $('.navigation-buttons .fa').removeClass('active');

            //  Close Navigation sidebar if openn, and open Search sidebar:
            if (content.hasClass('openLeft')) {
                content.removeClass('openLeft').addClass('openLeftSearch');
                $(this)
                    .find('.fa')
                    .addClass('active');
                //searchBox.focus();
            }
            //  Close Search sidebar if open:
            else if (content.hasClass('openLeftSearch')) {
                content.removeClass('openLeftSearch');
            }
            //  Close Search sidebar if closed:
            else {
                content.addClass('openLeftSearch');
                $(this)
                    .find('.fa')
                    .addClass('active');
                //searchBox.focus();
            }
            //  Close Card sidebar if open:
            if (content.hasClass('openRight')) {
                content.removeClass('openRight');
            }
        });

    //  Init. CardIcon (Card sidebar):
    $('#cardicon a')
        .off('click')
        .on('click', function() {
            $('.navigation-buttons .fa').removeClass('active');

            //  Close Card sidebar if opened:
            if (content.hasClass('openRight')) {
                content.removeClass('openRight');
            }
            //  Open Card sidebar if closed:
            else {
                content.addClass('openRight');
                $(this)
                    .find('.fa')
                    .addClass('active');
                //  Close Navigation/Search sidebar if closed:
                content.removeClass('openLeft').removeClass('openLeftSearch');
            }
        });
}

interface String {
    toCamel(): string;
}
String.prototype.toCamel = function() {
    return this.substr(0, 1).toLowerCase() + this.substr(1);
};

// Fixes IE issues in the menucontroller by adding the name property to all functions
/*interface Function {
    name: string;
}*/

if (Function.prototype.name == undefined) {
    Object.defineProperty(Function.prototype, 'name', {
        get() {
            let val = /function ([^(]*)/.exec(this + '');
            if (val) return val[1];
            return undefined;
        }
    });
}

Number.prototype.toHHMMSS = function() {
    let sec_numb = this;

    let hours = Math.floor(sec_numb / 3600);
    let minutes: any = Math.floor((sec_numb - hours * 3600) / 60);
    let seconds: any = sec_numb - hours * 3600 - minutes * 60;

    //if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) {
        minutes = '' + minutes;
    }
    if (seconds < 10) {
        seconds = '0' + seconds;
    }

    // var time = hours + ':' + minutes + ':' + seconds;
    let time = minutes + ':' + seconds;
    return time;
};

function secondsToHms(d) {
    d = Number(d);

    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    return (h > 0 ? h + ':' : '') + (m < 10 ? '0' : '') + m + ':' + (s < 10 ? '0' : '') + s;
}

function toggleElementsDueToAuthorization(element, attr, disabled) {
    if (disabled) {
        element.attr('disabled', disabled);

        element.find('*').attr('disabled', disabled);
        element.bind('click.authorized', function(event) {
            event.stopImmediatePropagation();
            event.preventDefault();
        });
    } else {
        element.removeAttr('disabled');

        element.find('*').removeAttr('disabled');
        element.unbind('click.authorized');
    }
}
