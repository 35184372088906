/// <reference path="../../Window.d.ts"/>

namespace Umbrella {
    import PhotoBlobUrlService = Modules.Colleagues.PhotoBlobUrlService;
    import QuickLinkModel = Modules.QuickLinks.QuickLinkModel;
    import QuickDialResource = Modules.Telephony.QuickDialResource;
    import QuickDialModel = Modules.Telephony.QuickDialModel;
    import PhoneService = Modules.Telephony.PhoneService;

    @Component('Umbrella', {
        selector: 'top-toolbar',
        templateUrl: '/Top/TopToolbarComponent/TopToolbar.html'
    })
    @Inject('$location', 'PhotoBlobUrlService', 'QuickLinkResource', 'QuickDialResource', 'PhoneService', 'ConfigResource')
    class TopToolbarComponent {
        public quicklinks: QuickLinkModel[];
        public quickDials: QuickDialModel[];
        public user: Modules.Colleagues.SessionColleagueModel;
        public photoUrl: string;
        public displayPanel = false;
        public displayDeveloperTools = false;
        public powerBiWorkspaceId : Guid = null;
        private subscription: Rx.IDisposable;

        constructor(
            private $location: ng.ILocationService,
            private photoBlobUrlService: PhotoBlobUrlService,
            private quickLinkResource,
            private quickDialResource: QuickDialResource,
            private phoneService: PhoneService,
            private configResource: Modules.IConfigResourceClass
        ) {}

        public $onInit() {
            this.loadQuickDials();
            this.loadQuickLinks();
            this.loadPowerBiWorkspaceId();
            this.subscription = session$
                .skipWhile(x => !x.user)
                .map(x => x.user)
                .subscribe(user => {
                    this.user = user;
                    this.photoUrl = this.photoBlobUrlService.photoUrl(user.pictureBlobId, 65, 65);
                });
        }

        public $onDestroy() {
            this.subscription.dispose();
        }
        public loadPowerBiWorkspaceId(){
            this.configResource.get({section: 'reporting'})
                .$promise.then(val => (this.powerBiWorkspaceId = val.powerBiWorkspaceId));
        }

        public isTelephonyAgentRegistered(): boolean {
            return window.config && window.config.facets.telephony;
        }

        public loadQuickLinks(): void {
            if (!window.user.permissions.quickLinkRead) return;

            this.quickLinkResource
                .getAllActive({ page: 0, pageSize: 100 })
                .$promise.then(data => (this.quicklinks = data));
        }

        public loadQuickDials(): void {
            if (!this.isTelephonyAgentRegistered() || !window.user.permissions.quickDialRead) return;

            this.quickDialResource.getAllActive().$promise.then((data: QuickDialModel[]) => {
                this.quickDials = data;
            });
        }

        public callNr(nr: string): void {
            this.phoneService.callNr(nr);
            this.togglePanel();
        }

        public callNrDirect(nr: string): void {
            this.phoneService.callNrDirect(nr);
            this.togglePanel();
        }

        public togglePanel(): void {
            this.displayPanel = !this.displayPanel;
        }

        public toggleDeveloperTools(): void {
            this.displayDeveloperTools = !this.displayDeveloperTools;
        }

        public isKnowledgebaseActive() {
            return this.urlStartsWith('/kennisbank');
        }

        public isReportingActive() {
            return this.urlStartsWith('/rapportage');
        }

        public isSelfServiceActive() {
            return this.urlStartsWith('/selfservice');
        }

        public isShellActive() {
            return this.urlStartsWith('/shell');
        }

        public isXmlConfigActive() {
            return this.urlStartsWith('/xmlconfig');
        }

        public isAdminActive() {
            return this.urlStartsWith('/beheer');
        }

        public displayDownloadButton(): boolean {
            return !!window.deferredPrompt;
        }

        public downloadApp(): void {
            window.deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            window.deferredPrompt.userChoice.then(choiceResult => {
                window.deferredPrompt = null;
            });
        }

        public logout(): void {
            location.href = Config.makeAbsoluteApiUrl('/logout');
        }

        private urlStartsWith(prefix: string): boolean {
            return this.$location.path().indexOf(prefix) === 0;
        }
    }
}
