/// <reference path="Config/Config.ts" />
/// <reference path="Window.d.ts" />

namespace Umbrella {
    export interface SessionState {
        user: Umbrella.Modules.Colleagues.SessionColleagueModel;
        config: Config;
        accessToken: string;
        error: Error;
    }

    export const session$ = new Rx.BehaviorSubject<SessionState>({
        user: null,
        accessToken: null,
        config: null,
        error: null
    });

    function startSession(cfg: Config, url: string): void {
        var loginredirect = cfg.loginredirect;
        
        const headers = {};
        if (cfg.features && cfg.features.length) {
            const winAuthFlag = cfg.features.find(f => f.name === 'windowsAuthentication');
            if (winAuthFlag && winAuthFlag.enabled)
                headers['X-Umbrella-Windows-Auth'] = 'true';
        }

        $.ajax(url, {
            //method: 'get',
            //mode: 'cors',
            dataType: 'json',
            //credentials: 'include',

            headers: headers,
            cache: false,
            success: x => {
                if (loginredirect) {
                    if (x.user == null) {
                        window.location.assign(Config.makeAbsoluteApiUrl('go/' + window.location.hash.substr(2)));
                    } else {
                        session$.onNext(x)
                    }
                } else {
                    session$.onNext(x)
                }
            },
            error: xhr => {
                switch (xhr.status) {
                    case 0:
                        window.location.assign(Config.makeAbsoluteApiUrl('go/' + window.location.hash.substr(2)));
                        break;
                    case 500:
                        session$.onNext({
                            user: null,
                            accessToken: null,
                            config: null,
                            error: {
                                name: 'NotAuthorized',
                                message: '',
                                stack: ''
                            }
                        });
                        break;
                    default:
                        if (loginredirect)
                            window.location.assign(Config.makeAbsoluteApiUrl('go/' + window.location.hash.substr(2)));
                        break;
                }
            }
        });
    }

    Config.configStore.state$
        .map(state => state && state.config)
        .skipWhile(cfg => !cfg)
        .take(1)
        .subscribe(cfg => startSession(cfg, cfg.api + 'api/v1/system/session'));

    session$.subscribe(session => {
        window.user = session.user;
        window.config = session.config;
        window.session = session;
    });

    export function isDevModeOrVersionAtLeast(major: number, minor: number, patch: number) {
        if (window.config.configuration.toLowerCase() == 'debug') return true;

        if (
            window.config.version.major >= major &&
            window.config.version.minor >= minor &&
            window.config.version.patch >= patch
        )
            return true;

        return false;
    }

    export function startNewSessionWhenTokenHasExpired(): void {
        startSession(window.clientConfig, Config.makeAbsoluteApiUrl('api/v1/system/session'));
    }

    export function whenAuthenticatedKccChatOperator(onReady: () => void) {
        const isAuthenticatedKccChatOperator = (sessionState: SessionState) => {
            return (
                sessionState &&
                sessionState.accessToken &&
                sessionState.user &&
                sessionState.user.permissions &&
                sessionState.user.permissions.chatOperator &&
                sessionState.user.permissions.viewChatConversation
            );
        };
        session$
            .filter(isAuthenticatedKccChatOperator)
            .take(1)
            .subscribe(_ => {
                onReady();
            });
    }
}
