/// <reference path="../Scripts/TypeScript/angularjs/angular.d.ts"/>
/// <reference path="../Scripts/TypeScript/umbrella/umbrella.d.ts"/>
/// <reference path="../Scripts/TypeScript/rxjs/rx.lite.es6.d.ts"/>
/// <reference path="../Scripts/TypeScript/rxjs/rx.lite.experimental.d.ts"/>
/// <reference path="../RootStore.ts"/>

namespace Umbrella.Config {
    export interface FeatureFlag {
        name: string;
        displayName: string;
        enabled: boolean;
        description: string;
    }

    export interface Facet {
        available: boolean;
        agent: string;
        type: 'Facet'
    }

    export interface Module {
        enabled: boolean;
    }

    export interface TimelineConfig {
        enabled: boolean;
        userCurrentPhoneCallLoggingEnabled: boolean;
        activityModificationTimeLimitInMinutes: number;
    }

    export interface Modules extends Map<string, Module[]> {
        timeline: TimelineConfig
    }

    export interface Config {
        api: string;
        debug: boolean;
        signalRTransports: string[] | 'auto';
        bugsnagApiKey: string;
        features: FeatureFlag[];
        experimentalAreaEnabled: boolean;
        managementRelativeUrl: string;
        managementErrorPath: string;
        managementLogPath: string;
        managementPortalPath: string;
        managementSynchronizedObjectPath: string;
        loginredirect: boolean;
        environmentName: string;
        facets: Map<string, Facet[]>;
        modules: Modules;
    }

    export interface ConfigLoadingEvent {
        type: 'ConfigLoadingEvent';
    }

    export interface ConfigLoadedEvent {
        type: 'ConfigLoadedEvent';
        config: Config;
    }

    export interface ConfigLoadErrorEvent {
        type: 'ConfigLoadErrorEvent';
        error: any;
    }

    export interface FeatureFlagChangedEvent {
        type: 'FeatureFlagChangedEvent';
        features: FeatureFlag[];
    }

    export interface ExperimentalAreaStateChanged {
        type: 'ExperimentalAreaStateChanged';
        enabled: boolean;
    }

    export type ConfigEvent =
        | ConfigLoadingEvent
        | ConfigLoadedEvent
        | ConfigLoadErrorEvent
        | FeatureFlagChangedEvent
        | ExperimentalAreaStateChanged;

    export interface ConfigState {
        config: Config;
        loadError: any;
    }

    const reducer: ObservableStore.EventHandler<ConfigState, ConfigEvent> = e => {
        switch (e.type) {
            case 'ConfigLoadingEvent':
                return s => ({ config: null, loadError: null });
            case 'ConfigLoadedEvent':
                return s => ({ config: e.config, loadError: null });
            case 'ConfigLoadErrorEvent':
                return s => ({
                    config: {
                        api:
                            window.location.origin.indexOf('localhost') > -1
                                ? 'http://api.umbrella.localhost/'
                                : window.location.origin + '/',
                        debug: true,
                        signalRTransports: 'auto',
                        bugsnagApiKey: 'none',
                        features: []
                    },
                    loadError: e.error
                });
            case 'FeatureFlagChangedEvent':
                return s => ({
                    ...s,
                    config: {
                        ...s.config,
                        features: e.features
                    }
                });
            case 'ExperimentalAreaStateChanged':
                return s => ({
                    ...s,
                    config: {
                        ...s.config,
                        experimentalAreaEnabled: e.enabled
                    }
                });
        }
    };

    export const configStore = ObservableStore.create<ConfigState, ConfigEvent>(
        { config: null, loadError: null },
        reducer
    );

    export function changeExperimentalAreaState(enabled: boolean) {
        configStore.event$.onNext({
            type: 'ExperimentalAreaStateChanged',
            enabled
        });
    }

    export async function load() {
        configStore.event$.onNext({ type: 'ConfigLoadingEvent' });

        let config: Config;
        let error;

        try {
            await $.getJSON('/Config/config.json', configJson => {
                config = {
                    ...config,
                    ...configJson
                };
            });
        } catch (e) {
            error = e;
        }

        try {
            await $.getJSON('/Config/features.json', featuresJson => {
                config.features = featuresJson;
                config.features.forEach(f => {
                    const state = localStorage.getItem(f.name);
                    if (state === null) return;
                    f.enabled = state == 'true';
                });        
            });
        } catch (e) {
            error = e;
        }

        const experimentalAreaUnlocked = localStorage.getItem('ExperimentalAreaUnlocked');
        if (experimentalAreaUnlocked && JSON.parse(experimentalAreaUnlocked) === 'true') {
            config.experimentalAreaEnabled = true;
        } else {
            config.experimentalAreaEnabled = false;
        }

        if (error) configStore.event$.onNext({ type: 'ConfigLoadErrorEvent', error });
        else configStore.event$.onNext({ type: 'ConfigLoadedEvent', config });
    }

    export function switchFeatureFlag(flagName: string, enabled: boolean) {
        const state = configStore.getState();

        if (!state || !state.config || !state.config.features) return;
        const features = state.config.features;

        const feature = features.find(x => x.name === flagName);
        if (feature) {
            const newFeatures = features.map(x => {
                if (x.name === flagName) x.enabled = enabled;
                return x;
            });

            configStore.event$.onNext({
                type: 'FeatureFlagChangedEvent',
                features: newFeatures
            });
        }
    }

    load();

    configStore.state$.subscribe(s => {
        window.clientConfig = s.config;
    });

    configStore.state$.subscribe(s => {
        if (s.config && s.config.features && s.config.features.length) {
            s.config.features.forEach(f => {
                sessionStorage.setItem(f.name, f.enabled.toString());
            });
        }
    });


    function getPathWithTrailingSlash(path: string): string {
        if (!path || !path.length || path.endsWith('/') || path.includes('?')) return path;

        return `${path}/`;
    }

    export function getManagementLogRelativeUrl(): string {
        const logPath = getPathWithTrailingSlash(window.clientConfig.managementLogPath || '/#/beheer/systeem/log?scopeId=');
        return `/${getManagementRelativeUrl()}${logPath}`;
    }

    function getManagementRelativeUrl(): string {
        const subUrl = window.clientConfig.managementRelativeUrl || 'beheer';
        return getPathWithTrailingSlash(subUrl);
    }

    export function getPortalUrl(): string {
        return (
            (window.config &&
                window.config.modules &&
                window.config.modules.selfService &&
                window.config.modules.selfService.url) ||
            null
        );
    }

    export function makeAbsoluteApiUrl(requestUrl: string): string {
        const urlWithStartingSlash = requestUrl[0] === '/' ? requestUrl.substr(1) : requestUrl;
        return urlWithStartingSlash.indexOf(window.clientConfig.api) > -1
            ? urlWithStartingSlash
            : window.clientConfig.api + urlWithStartingSlash;
    }

    export function makeManagementErrorPath(id: Guid): string {
        const errorPath = getPathWithTrailingSlash(
            window.clientConfig.managementErrorPath || '/#/beheer/monitoring/diagnostiek/servicebus/fouten/'
        );
        return `/${getManagementRelativeUrl()}${errorPath}${id}`;
    }

    export function makeManagementLogPath(id: Guid): string {
        return `${getManagementLogRelativeUrl()}$${id}`;
    }

    export function makeManagementPortalPath(id: Guid): string {
        const portalPath = getPathWithTrailingSlash(
            window.clientConfig.managementPortalPath || '/#/beheer/klantportaal/aanvragen/'
        );
        return `/${getManagementRelativeUrl()}${portalPath}${id}`;
    }

    export function makeManagementSynchronizedObjectPath(id: Guid): string {
        const synchronizedObjectPath = getPathWithTrailingSlash(
            window.clientConfig.managementSynchronizedObjectPath ||
                '/#/beheer/monitoring/gesynchroniseerdeobjecten?query='
        );
        return `/${getManagementRelativeUrl()}${synchronizedObjectPath}${id}`;
    }

    $.ajaxSetup({
        xhrFields: {
            withCredentials: true
        },
        headers: { 'Access-Control-Allow-Origin': '*' }
    });
}
